<template>
  <div>
    <section class="page-title text-center">
      <div class="container">
        <div class="content-box">
          <h1>{{ $t('Bay Done') }}</h1>
        </div>
      </div>
    </section>

    <!--SECTION START-->
    <section
      style="background-color: white;padding-top: 120px;padding-bottom: 120px;"
      class="text-center"
    >
      <img src="../../assets/sccess.png" alt="" v-if="done == 'success'" />
      <img src="../../assets/error.webp" alt="" v-else />
    </section>
  </div>
</template>
<script>
export default {
  data() {
    return {
      screen: null,
      id: null,
      done: null,
    };
  },
  methods: {
    updateDataById() {
      this.$http2
        .post(`massageorder/update/${this.id}`, {
          
          bayDone:true,
        })
        .then((res) => {

        });
    },
    updateGiftById() {
      this.$http2
        .post(`gifts/update/${this.id}`, {
          
          bayDone:true,
        })
        .then((res) => {

        });
    },
  },
  created() {
    this.screen = this.$route.params.screen;
    this.id = this.$route.params.id;
    this.done = this.$route.params.done;
    if (this.screen == 'massageorder' && this.done == 'success') {
      this.updateDataById();
    }

     if (this.screen == 'gift' && this.done == 'success') {
      this.updateGiftById();
    }
    // if (this.screen == 'massageorder') {
    // }
  },
};
</script>
